import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-reader',
  templateUrl: './modal-reader.component.html',
  styleUrls: ['./modal-reader.component.scss']
})
export class ModalReaderComponent implements OnInit, OnDestroy {

  state = false;
  loading = true;
  public safeUrl: SafeResourceUrl;

  constructor( private sanitizer: DomSanitizer,
              private modalService: ModalService ) {
    this.modalService.notifyObservableReader$.subscribe((url: string) => {
      this.state = true;
      this.loading = true;
      if (url === '') {
        this.state = false;
      } else if (url === 'loading') {
        this.loading = true;
      } else {
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.loading = false;
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.modalService.broadcastReader.unsubscribe();
  }
}
