import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClassService} from '../class.service';
import {BooksService} from '../books.service';
import {combineLatest, Subscription} from 'rxjs';
import {Book} from '../models/book';
import {TranslateService} from '@ngx-translate/core';
import {isNullOrUndefined} from 'util';
import { Classroom } from '../models/classroom';

declare let gtag: Function;

@Component({
  selector: 'app-my-student-stats',
  templateUrl: './my-student-stats.component.html',
  styleUrls: ['./my-student-stats.component.scss']
})
export class MyStudentStatsComponent implements OnInit {
  classroom$: Subscription;
  studentName = '';
  classroomName = '';
  studentUsername: string;
  classroomToken: string;
  classroomStatByLibro: any[];
  classroomStatByLibroCompetenza: any[];
  loading = true;
  currentLanguage = 'en';
  getTotalStat = Book.getTotalStat;
  badgeClass = Book.badgeClass;
  getAverageStatByBook = Book.getAverageStatByBook;

  constructor(private _route: ActivatedRoute,
              private _classService: ClassService,
              private _bookService: BooksService,
              private _translate: TranslateService,
              private _router: Router) {
    this.currentLanguage = this._translate.currentLang;
    this._translate.onLangChange.subscribe((event) => {
      this.currentLanguage = event.lang;
    });

    this._route.params.subscribe(params => {
      this.studentUsername = params['username'];
      this.classroomToken = params['classroom'];
      combineLatest(this._classService.getClassroomStats(this.classroomToken),
        this._bookService.getCatalogue(), (stat, books) => ({
        stat, books
      })).subscribe(({stat, books}) => {
        this.loading = true;
        const statsCurrentUser = stat['docente_groupby_libro_id_studente_id'].filter((item) => {
          return item['username_studente'] === this.studentUsername;
        });
        const statsByLibro = [];
        statsCurrentUser.forEach((item) => {
          item['book'] = books.find((book: Book) => {
            return item.sku === book.sku;
          });
          statsByLibro.push(item);
        });
        this.classroomStatByLibro = statsByLibro.filter(item => {
          return !isNullOrUndefined(item.book);
        });

        const statsCurrentUserByCompetence = stat['docente_groupby_libro_id_studente_id_competenza_id'].filter((item) => {
          return item['username_studente'] === this.studentUsername;
        });
        const statsByLibroCompetenza = [];
        statsCurrentUserByCompetence.forEach((item) => {
          if (!(item.sku in statsByLibroCompetenza)) {
            statsByLibroCompetenza[item.sku] = [];
          }
          statsByLibroCompetenza[item.sku].push(item);
        });
        this.classroomStatByLibroCompetenza = statsByLibroCompetenza;

        if (this.filteredBooksByLanguage().length > 0) {
          this.studentName = this.filteredBooksByLanguage()[0]['nome_studente'] +
            ' ' + this.filteredBooksByLanguage()[0]['cognome_studente'];

          this.classroom$ = this._classService.getTeacherClasses().subscribe((classes) => {
            const classroom1 = classes.find((classroom: Classroom) => {
              return classroom.token === this.classroomToken;
            });
            this.classroomName = classroom1.nome;
            
            gtag("event", "screen_view", {
              "screen_name" : "cideb_teacher_classroom_studentstats/" + this.classroomName + " (" + this.classroomToken + ")/" + this.studentName,
            });
          });
          
        }
        this.loading = false;
      });
    }, () => {
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.classroom$.unsubscribe();
  }

  booksEmpty() {
    return this.classroomStatByLibro.length === 0;
  }

  filteredBooksByLanguage() {
    return this.classroomStatByLibro.filter(item => {
      return !isNullOrUndefined(item.book.translations[this.currentLanguage]) ?
        item.book.translations[this.currentLanguage] : item.book.getFirstAvailableLanguageInMetadatasTranslations();
    });
  }

  getLanguage(language) {
    return Book.languages[Book.languageMappingFromItalian[language]].toLowerCase();
  }

  goToBookStat(classroom: string, book: string) {
    this._router.navigate(['teacher/my-classrooms', classroom, 'books', book]);
  }
}
