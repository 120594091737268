import {Component, OnInit, Renderer2} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { AuthService } from '../auth.service';
import { ModalService } from '../modal.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BooksService } from '../books.service';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { setCookie } from '../utils';

declare let gtag: Function;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('openState', [
      state('closed', style({
        opacity: 0,
        visibility: 'hidden',
        display: 'none'
      })),
      state('open', style({
        opacity: 1,
        visibility: 'visible',
        display: 'block'
      })),
      transition('closed => open', animate('200ms ease-in')),
      transition('open => closed', animate('200ms ease-out'))
    ])
  ]
})
export class NavigationComponent implements OnInit {

  state = 'closed';
  isLoggedIn$: boolean = false;
  languages = environment.languages;
  currentUrl: string;
  hideIfEmbedded = false;

  constructor(private _translate: TranslateService, private _authService: AuthService,
              private _modalService: ModalService, private _router: Router,
              private _renderer: Renderer2, private _route: ActivatedRoute,
              private _booksService: BooksService, private _location: Location) {
    this._route.queryParams.subscribe(params => {
      if (params['login']) {
        this.hideIfEmbedded = true;
      }
    });
  }

  ngOnInit() {
    // this.isLoggedIn$ = this._authService.isAuthenticated();
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // this.isLoggedIn$ = this._authService.isAuthenticated();
        this.closeMenu();
      }
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        // console.log("calling");
        this._authService.isAuthenticated().subscribe((isAuthenticated) => this.isLoggedIn$ = isAuthenticated);
      }
    });
  }

  switchLanguage(lang: string): void {
    setCookie('ereaders_lang', lang, 3153600000);
    this._translate.use(lang);
    gtag('event','change_language', { 
      languageCode: lang,
      previous_languageCode: this._translate.currentLang
    });
    gtag('set', 'user_properties', {
      'language': lang
    });
  }

  isCurrentLanguage(lang: string): boolean {
    return lang === this._translate.currentLang;
  }

  closeMenu(): void {
    this.state = 'closed';
    this._renderer.removeClass(document.body, 'menu-open');
  }

  openMenu(): void {
    this.state = 'open';
    this._renderer.addClass(document.body, 'menu-open');
  }

  goBack(): void {
    if (window.history.length > 1) {
      this._location.back();
    } else {
      this._router.navigateByUrl("/");
    }
  }

  openModalCoupon(): void {
    this._modalService.openModalCoupon();
  }

  isHomepage(): boolean {
    return this.currentUrl === '/';
  }

  isLoginpage() {
    return this.currentUrl === '/login';
  }

  public isLoginMobilePage(): boolean {
    return this._router.url === '/login-mobile';
  }

  reloadCatalogue(): void {
    this._booksService.reload();
  }

  logout(): void {
    this.closeMenu();
    this._authService.logout();
  }

  isTeacher(): boolean {
    return this._authService.isTeacher();
  }

  getName(): string {
    return this._authService.getNome() + ' ' + this._authService.getCognome();
  }
}
