import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeEachWord'
})
export class CapitalizeEachWordPipe implements PipeTransform {
  transform(value: string, args: any[]): string {
    if (value === null) { return 'Not assigned'; }
    return value.split(' ').map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }
}
