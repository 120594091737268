import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from '../auth.service';
// import {environment} from '../../environments/environment';
import {ApplicationService} from '../application.service';
import {NotificationService} from '../notification.service';
// import { randomstring, isSafari } from '../utils';
// import {isNullOrUndefined} from 'util';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// import { ConnectionBackend } from '@angular/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  // @ViewChild('iframeLogin') iframeLogin: ElementRef;

  private url: string;
  public currentLang: string;
  public showIframe = false;
  public showLoginButton = false;
  public loading = false;
  // public safeUrl: SafeResourceUrl;
  public popupBlocked = false;
  // public isSafari = isSafari;
  form: FormGroup;
  loginErrorMsg: string = null;


  constructor(private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private _authService: AuthService,
              private router: Router,
              private _renderer: Renderer2,
              private _applicationService: ApplicationService,
              private _notificationService: NotificationService,
              private _translate: TranslateService,
              private fb: FormBuilder,
              private translate: TranslateService) {
    this.currentLang = this._translate.currentLang;
    this._translate.onLangChange.subscribe(({lang}) => {
      this.currentLang = lang;
    });
  }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
  }

  createForm() {
    this.form = this.fb.group({
      emailField: [ '', [Validators.required, Validators.email] ],
      passwordField: [ '', Validators.required ]
    });
  }

  async onSubmit() {
    // make sure fields values are updated even with autocomplete
    document.getElementById("loginEmail").dispatchEvent(new Event('input', {
      bubbles: true,
      cancelable: true,
    }));
    document.getElementById("loginPassword").dispatchEvent(new Event('input', {
      bubbles: true,
      cancelable: true,
    }));

    // check validators
    var errMsg;
    if (this.form.get("emailField").hasError("required") || this.form.get("passwordField").hasError("required")) {
      errMsg = "login.AllFieldsAreRequired";
    } else if (this.form.get("emailField").hasError("email")) {
      errMsg = "login.PleaseUseValidEmailAddress";
    }
    if (errMsg) {
      this.translate.get(errMsg).subscribe((s:string) => (this.loginErrorMsg=s));
      return false;
    } else {
      this.loginErrorMsg = null;
    }

    // login!
    this.loading = true;
    try {
      var rslt = await this._authService.login(this.form.get("emailField").value, this.form.get("passwordField").value);
    } catch (err) {
      // console.log(err);
      if (err.status == 401) {
        this.translate.get("login.LoginIncorrect").subscribe((s:string) => (this.loginErrorMsg=s));
      } else {
        this.loginErrorMsg = err.message;
      }
      this.loading = false;
      return;
    }
    this.router.navigateByUrl('/');
    this.loading = false;
  }

}
