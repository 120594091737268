import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { BooksService } from '../books.service';
import { Book } from '../models/book';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { fade } from '../animations';
import { NotificationService } from '../notification.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-my-stats',
  templateUrl: './my-stats.component.html',
  styleUrls: ['./my-stats.component.scss'],
  animations: [ fade ]
})
export class MyStatsComponent implements OnInit, OnDestroy {

  objectKeys = Object.keys;
  loadingBookStats = true;
  loading = true;
  loadingStudentStats = true;
  books$: Subscription;
  books: Book[];
  bookStats$: Subscription;
  bookStats = [];
  studentStats$: Subscription;
  studentStats = {};

  constructor(private _booksService: BooksService,
              private _translate: TranslateService,
              private _notificationService: NotificationService,
              private _router: Router) {
    this.books$ = this._booksService.getBooks().subscribe(
      (res: Book[]) => {
        this.books = res;
        this.loading = false;
        this.bookStats$ = this._booksService.getStatsGeneraliLibro().subscribe(
          (res2) => {
            // console.log(res2);
            this.bookStats = res2;
            this.loadingBookStats = false;
          },
          err => {
            this.loadingBookStats = false;
            this._notificationService.httpError();
            console.error(err);
          });
      },
      err => {
        this.loading = false;
        this._notificationService.httpError();
        console.error(err);
      });
    this.studentStats$ = this._booksService.getStatsGeneraliStudente().subscribe(
      (res: any[]) => {
        // console.log(res);
        const stats = {};
        res.forEach(competence => {
          if (stats[competence.nome_lingua] === null || typeof stats[competence.nome_lingua] !== 'object') {
            stats[competence.nome_lingua] = {};
          }
          if (!Array.isArray(stats[competence.nome_lingua][competence.nome_livello])) {
            stats[competence.nome_lingua][competence.nome_livello] = [];
          }
          stats[competence.nome_lingua][competence.nome_livello].push(competence);
        });
        this.studentStats = stats;
        this.loadingStudentStats = false;
      },
      err => {
        this.loadingStudentStats = false;
        this._notificationService.httpError();
        console.log(err);
      }
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.books$.unsubscribe();
    this.bookStats$.unsubscribe();
    this.studentStats$.unsubscribe();
  }

  booksEmpty(): boolean {
    return this.books && this.books.length === 0;
  }

  hasStat(book: Book) {
    return !!this.bookStats[book.sku];
  }

  getAverageStatByBook(stat) {
    var value = this.floatifyStat(stat['average_closed']);
    if (parseFloat(value) == 0) value = "N/A";
    return value;
  }

  findStat(book: Book): string {
    return this.getTotalStat(this.bookStats[book.sku]);
  }

  getTotalStat(stat) {
    const total_closed = parseFloat(stat['totalex_closed']);
    const totalsent_closed = parseFloat(stat['totalsent_closed']);
    const totalex_open = parseFloat(stat['totalex_open']);
    const totalsent_open = parseFloat(stat['totalsent_open']);

    return (100 * (totalsent_closed + totalsent_open) / (total_closed + totalex_open)).toFixed(1);
  }

  badgeClass(stat) {
    const voto = parseFloat(this.floatifyStat(stat['average_closed']));
    
    if (voto == 0) {
      return 'badge-gray';
    } else if (voto <= 5) {
      return 'badge-danger';
    } else if (voto >= 6) {
      return 'badge-success';
    } else {
      return 'badge-orange';
    }
  }

  currentLanguage(): string {
    return this._translate.currentLang;
  }

  floatifyStat(stat): string {
    return (parseFloat(stat) / 10).toFixed(1);
  }

  getBooksWithStats() {
    return this.books.filter(book => this.hasStat(book));
  }

  getLanguage(language) {
    var languageLabel = language;
    var languageCode = Book.languagesFromNativeLanguageToISO[language];
    if (languageCode !== undefined) languageLabel = this._translate.instant("languages."+languageCode);
    return languageLabel;
  }

  goToBookStat(book: string) {
    this._router.navigate(['book', book]);
  }
}
