import { Component, OnInit } from '@angular/core';
import {ModalService} from '../modal.service';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-button-add-classroom',
  templateUrl: './button-add-classroom.component.html',
  styleUrls: ['./button-add-classroom.component.scss']
})
export class ButtonAddClassroomComponent implements OnInit {

  constructor(private _modalService: ModalService, private _authService: AuthService) { }

  ngOnInit() {
  }

  openModalNewClass(): void {
    if (this._authService.isTeacher()) {
      this._modalService.openModalNewClass();
    }
  }
}
