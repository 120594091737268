import {Component, OnDestroy, OnInit} from '@angular/core';
import {Book} from '../models/book';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BooksService} from '../books.service';
import {NotificationService} from '../notification.service';
import {ModalService} from '../modal.service';
import {ClassService} from '../class.service';
import {TranslateService} from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-edit-class',
  templateUrl: './edit-class.component.html',
  styleUrls: ['./edit-class.component.scss']
})
export class EditClassComponent implements OnInit, OnDestroy {

  class: any;
  catalogue = [];
  catalogueByLanguage = [];
  form: FormGroup;
  loading = false;
  submitted = false;
  currentLanguage = 'en';

  constructor(private fb: FormBuilder,
              private _booksService: BooksService,
              private _notificationService: NotificationService,
              private _modalService: ModalService,
              private _classService: ClassService,
              private _translate: TranslateService) {
    this.currentLanguage = this._translate.currentLang;
    this._translate.onLangChange.subscribe((event) => {
      this.currentLanguage = event.lang;
    });
    this._booksService.getCatalogue().subscribe((catalogue: Book[]) => {
      this.catalogue = catalogue;
    }, (err) => {
      console.log(err);
    });
    this._modalService.notifyObservableEditClass$.pipe(filter(x => !!x)).subscribe((classroom) => {
      this.class = classroom;
      this.form.setValue({
        books: this.class.libri.map((book: Book) => {
          return book.sku;
        })
      });
      this.catalogueByLanguage = this.catalogue.filter((book: Book) => {
        return !book.isAudio;
      }).filter((book: Book) => {
        return this.class.iso === book.getLanguage(this.currentLanguage);
      });
      this.catalogueByLanguage.sort((a, b) => {
        if (a.title < b.title) { return -1; }
        if (a.title > b.title) { return 1; }
        return 0;
      });
    }, (err) => {
      console.log(err);
    });
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
  }

  createForm() {
    this.form = this.fb.group({
      books: [ [], Validators.required ],
    });
  }

  getBooksFromSku() {
    return this.catalogueByLanguage.filter((book: Book) => {
      return this.form.get('books').value.indexOf(book.sku) !== -1;
    }).map((book: Book) => {
      return book.getTitleByLanguage(this.currentLanguage);
    }).join(', ');
  }

  onSubmitCreate() {
    if (this.form.valid) {
      this.submitted = true;
    }
  }

  onSubmitConfirm() {
    if (this.form.valid) {
      this.loading = true;
      this._classService.editClass(this.class, this.form.get('books').value).subscribe(res => {
        if (res.status !== 'KO') {
          this._modalService.closeModalEditClass();
          this._classService.reload();
          this._translate.get('class.edit-success').subscribe((resp) => {
            this._notificationService.success(resp, '');
          });
        } else {
          this.loading = false;
        }
      }, err => {
        console.log(err);
      }, () => {
        this.loading = false;
        this.resetSubmit();
      });
    }
  }

  onCancelConfirm() {
    this.submitted = false;
  }

  resetSubmit() {
    this.form.reset();
    this.submitted = false;
  }
}
