import { trigger, animate, style, transition, keyframes, state } from '@angular/animations';

export const fade = trigger('fade', [
  state('void', style({ opacity: 0 })),
  transition(':enter', [
    animate(600),
    style({
      opacity: 0
    })
  ]),
  transition(':leave', [
    animate(400,
      keyframes([
        style({ opacity: 1, offset: 1 })
      ])),
  ])
]);

export const expand = trigger('expand', [
  state('void', style({ height: 0 })),
  transition(':enter', [
    animate(600),
    style({
      height: 'auto'
    })
  ]),
  transition(':leave', [
    animate(400,
      keyframes([
        style({ height: 0 })
      ])),
  ])
]);
