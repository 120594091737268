import { Injectable } from '@angular/core';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService {

  constructor(private _authService: AuthService) { }

  canActivate(): boolean {
    return this._authService.isTeacher();
  }
}
