import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {IMyDpOptions} from 'mydatepicker';
import {NotificationService} from '../notification.service';
import {ModalService} from '../modal.service';
import {ClassService} from '../class.service';
import {Router} from '@angular/router';
import { IMyDate } from 'mydatepicker';
import * as moment from 'moment';

declare let gtag: Function;

@Component({
  selector: 'app-new-class',
  templateUrl: './new-class.component.html',
  styleUrls: ['./new-class.component.scss'],
})
export class NewClassComponent implements OnInit {

  form: FormGroup;
  languages = [
    {
      id: 'en',
      value: 'English'
    },
    {
      id: 'de',
      value: 'German',
    },
    {
      id: 'es',
      value: 'Spanish',
    },
    {
      id: 'fr',
      value: 'French'
    }
  ];
  myDatePickerOptions: IMyDpOptions;
  submitted = false;
  loading = false;
  triedToSubmit = false;

  constructor(private fb: FormBuilder, private _translate: TranslateService,
              private _notificationService: NotificationService,
              private _modalService: ModalService,
              private _classService: ClassService,
              private _router: Router) {
    const disableUntil = <IMyDate> {
        year: parseInt(moment().format('YYYY'), 10),
        month: parseInt(moment().format('MM'), 10),
        day: parseInt(moment().format('DD'), 10)
    };
    this.myDatePickerOptions =  {
      dateFormat: 'dd.mm.yyyy',
      openSelectorOnInputClick: true,
      disableUntil
    };
  }

  get name() { return this.form.get('name'); }
  get date() { return this.form.get('date'); }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: [ null, Validators.required ],
      language: [ 'en', Validators.required ],
      date: [ null, Validators.required ]
    });
  }

  printDate() {
    return this.form.get('date').value.formatted;
  }

  getISODate(): string {
    return this.form.get('date').value ? moment(this.form.get('date').value.jsdate).format('YYYY-MM-DD HH:mm:ss') : '';
  }

  clearDate(): void {
    this.form.patchValue({ date: null });
  }
  onSubmitCreate() {
    if (this.form.valid) {
      this.triedToSubmit = false;
      this.submitted = true;
    } else {
      this.triedToSubmit = true;
    }
  }

  onSubmitConfirm() {
    if (this.form.valid) {
      this.loading = true;
      this._classService.createClass(this.form.get('name').value, this.getISODate(), this.form.get('language').value).subscribe(res => {
        if (res.status !== 'KO') {
          this._modalService.closeModalNewClass();
          this._router.navigateByUrl('/teacher/my-classrooms');
          this._classService.reload();
          this._translate.get('class.feedback').subscribe((resp) => {
            this._notificationService.success('', resp);
          });
          gtag("event", "cideb_teacher_newclassroom", {});
        } else {
          this.loading = false;
        }
      }, err => {
        console.log(err);
      }, () => {
        this.loading = false;
        this.resetSubmit();
      });
    }
  }

  onCancelConfirm() {
    this.resetSubmit();
  }

  resetSubmit() {
    this.clearDate();
    this.form.reset();
    this.submitted = false;
  }

  currentLocale() {
    return this._translate.currentLang;
  }

  onLanguageChange(): void {
    this.form.get('books').reset();
    !!this.form.get('language').value ? this.form.get('books').enable() : this.form.get('books').disable();
  }
}
