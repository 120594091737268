import {BookStats} from './book-stats';
import {environment} from '../../environments/environment';
import {isNullOrUndefined} from 'util';

export class Book {

  static languageMapping = {
    'en': 'english',
    'it': 'italian',
    'es': 'spanish',
    'de': 'german',
    'fr': 'french'
  };

  static languages = {
    'en': 'English',
    'it': 'Italiano',
    'es': 'Español',
    'de': 'Deutsch',
    'fr': 'Français',
  };

  static languagesFromNativeLanguageToISO = {
    'English': 'en',
    'Italiano': 'it',
    'Español': 'es',
    'Deutsch': 'de',
    'Français': 'fr'
  };

  static languageMappingFromItalian = {
    'Inglese': 'en',
    'Italiano': 'it',
    'Spagnolo': 'es',
    'Tedesco': 'de',
    'Francese': 'fr',
  };

  static languageMappingFromEnglish = {
    'English': 'en',
    'Italian': 'it',
    'Spanish': 'es',
    'German': 'de',
    'French': 'fr',
  };

  id: number;
  translations: object[];
  cover: any;
  difficulty: string;
  series: string;
  copyright: string;
  published: string;
  stats?: BookStats;
  sku: string;
  metadatas: object[];
  itemId: string;
  title: string;
  isAudio = false;
  capitolo?: any;

  static floatifyStat(stat): string {
    return (parseFloat(stat) / 10).toFixed(1);
  }

  static getAverageStatByBook(stat) {
    var value = Book.floatifyStat(stat['average_closed']);
    if (value == "0.0") value = "N/A";
    return value;
  }

  static getTotalStat(stat) {
    const total_closed = parseFloat(stat['totalex_closed']);
    const totalsent_closed = parseFloat(stat['totalsent_closed']);
    const totalex_open = parseFloat(stat['totalex_open']);
    const totalsent_open = parseFloat(stat['totalsent_open']);

    const divisor = (total_closed + totalex_open);

    return divisor > 0 ? (100 * (totalsent_closed + totalsent_open) / divisor).toFixed(1) : 0;
  }

  static badgeClass(stat) {
    const voto = parseFloat(Book.floatifyStat(stat['average_closed']));

    if (voto == 0) {
      return 'badge-gray';
    } else if (voto <= 5) {
      return 'badge-danger';
    } else if (voto >= 6) {
      return 'badge-success';
    } else {
      return 'badge-orange';
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (!!this.metadatas) {
      this.title = this.getTitleByLanguage(this.getFirstAvailableLanguageInMetadatasTranslations());
      if ('payloadformat' in this.metadatas
        &&
        this.metadatas['payloadformat'].translations[this.getFirstAvailableLanguageInMetadatasTranslations()].value === 'audio-playlist') {
        this.isAudio = true;
        this.title = this.title + ' - Audio';
      }
      if ('meta-collana' in this.metadatas) {
        this.title += ' / ' + this.metadatas['meta-collana'].translations[this.getFirstAvailableLanguageInMetadatasTranslations()].value;
      }
    }
    if (this.title === undefined && input.nome !== undefined) {
      // legacy for some stats APIs...
      this.title = input.nome;
    }
    return this;
  }

  getLanguage(lang = 'en') {
    if (!('meta-lingua' in this.metadatas)) {
      return this.getFirstAvailableLanguageInMetadatasTranslations();
    }
    const language = !!this.metadatas['meta-lingua'].translations[lang] ?
      this.metadatas['meta-lingua'].translations[lang].value :
      this.metadatas['meta-lingua'].translations[Object.keys(this.metadatas['meta-lingua'].translations)[0]].value;
    return Book.languagesFromNativeLanguageToISO[language];
  }

  hasTranslationForLanguage(lang: string) {
    return lang in this.translations;
  }

  getPublishedDate() {
    return !!this.published ? this.published : '';
  }

  getTitleByLanguage(lang: string) {
    return this.hasTranslationForLanguage(lang) && this.translations[lang].label !== null ?
      this.translations[lang].label : this.translations[this.getFirstAvailableLanguageInTranslations('label')].label;
  }

  getAuthorByLanguage(lang: string) {
    return this.hasTranslationForLanguage(lang) && this.translations[lang].author !== null ?
      this.translations[lang].author : this.translations[this.getFirstAvailableLanguageInTranslations('author')].author;
  }

  getPublisherByLanguage(lang: string) {
    return this.hasTranslationForLanguage(lang) && this.translations[lang].publisher !== null ?
      this.translations[lang].publisher : this.translations[this.getFirstAvailableLanguageInTranslations('publisher')].publisher;
  }

  getAbstractByLanguage(lang: string) {
    return this.hasTranslationForLanguage(lang) && this.translations[lang].abstract !== null ?
      this.translations[lang].abstract : this.translations[this.getFirstAvailableLanguageInTranslations('abstract')].abstract;
  }

  getSeriesByLanguage(lang: string) {
    return 'meta-collana' in this.metadatas ? !!this.metadatas['meta-collana'].translations[lang] ?
      this.metadatas['meta-collana'].translations[lang].value :
      this.metadatas['meta-collana'].translations[this.getFirstAvailableLanguageInMetadatasTranslations()].value : '';
  }

  getCopyrightByLanguage(lang: string) {
    return 'copyright' in this.metadatas ? !!this.metadatas['copyright'].translations[lang] ?
      this.metadatas['copyright'].translations[lang].value :
      this.metadatas['copyright'].translations[this.getFirstAvailableLanguageInMetadatasTranslations()].value : '';
  }

  getLevelByLanguage(lang: string) {
    return 'meta-livello' in this.metadatas ? !!this.metadatas['meta-livello'].translations[lang] ?
      this.metadatas['meta-livello'].translations[lang].value :
      this.metadatas['meta-livello'].translations[this.getFirstAvailableLanguageInMetadatasTranslations()].value : '';
  }

  hasImages(kind: string, lang: string) {
    return this[kind].length > 0 && !isNullOrUndefined(this.searchImageByLocale(this[kind], lang));
  }

  getCoverimageByLanguage(lang: string) {
    return this.hasCoverImage(lang) ?
      this.searchImageByLocale(this.cover, lang)['coverImage']['mediaurl'] :
      this.hasCoverImage(this.getFirstAvailableLanguageForImage()) ?
        this.searchImageByLocale(this.cover, this.getFirstAvailableLanguageForImage())['coverImage']['mediaurl']
        : '';
  }

  hasCoverImage(lang: string) {
    return !isNullOrUndefined(this.searchImageByLocale(this.cover, lang));
  }

  searchImageByLocale(images: any[], lang: string): any {
    return images[lang];
  }

  private getFirstAvailableLanguageInMetadatasTranslations() {
    return environment['languages'].find((language: string) => {
      return (language in this.translations);
    });
  }

  private getFirstAvailableLanguageInTranslations(field: string) {
    return environment['languages'].find((language: string) => {
      if (language in this.translations && !!this.translations[language][field]) {
        return (language in this.translations);
      }
    });
  }

  private getFirstAvailableLanguageForImage() {
    return environment['languages'].find((language: string) => {
      return (language in this.cover);
    });
  }

  public getMetadataDefaultValue(keyName: string) : string {
    try {
      if (keyName in this.metadatas) {
        return this.metadatas[keyName]["translations"]["en"]["value"];
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }



}
