import { EventEmitter, Injectable } from '@angular/core';
import { Book } from './models/book';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../environments/environment';
import { retry, map } from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import * as moment from 'moment';
import {ApplicationService} from './application.service';
import {isNullOrUndefined} from 'util';

@Injectable({
  providedIn: 'root'
})
export class BooksService {

  catalogue: Book[];
  books: Book[];
  reload$: EventEmitter<boolean>;
  stats;
  statsGeneraliLibro;
  statsGeneraliStudente;

  constructor(private http: HttpClient,
              private authService: AuthService,
              private _applicationService: ApplicationService) {
    this.reload$ = new EventEmitter();
  }

  reload() {
    this.books = null;
    this.stats = null;
    this.statsGeneraliLibro = null;
    this.statsGeneraliStudente = null;
    this.reload$.emit(true);
  }

  getBooks(): Observable<void | Book[]> {
    if (!isNullOrUndefined(this.books)) {
      return new BehaviorSubject(this.books).asObservable();
    }

    const username = this.authService.getUsername();
    const token = this.authService.getToken();
    const options = {params: new HttpParams().set('username', username).set('token', token)};
    return this.http.get<any[]>(environment.url.getBooks, options).pipe(
      map((books) => {
        this.books = this.newBooks(books);
        // console.log(books);
        return this.books;
      }),
      retry(3)
    );
  }

  getCatalogue(): Observable<Book[]> {
    if (!!this.catalogue) {
      return new BehaviorSubject(this.catalogue).asObservable();
    }

    return new Observable<Book[]>((observer) => {
      this._applicationService.getApplication().subscribe((application) => {
        this.catalogue = this.newBooks(application['items']);
        observer.next(this.catalogue);
        observer.complete();
      }, (err) => {
        console.log(err);
        observer.error();
        observer.complete();
      });
    });
  }

  getStats(): Observable<void | any> {
    if (!!this.stats) {
      return new BehaviorSubject(this.stats).asObservable();
    }

    const username = this.authService.getUsername();
    const email = this.authService.getEmail();
    const options = {params: new HttpParams()
      .set('username', username)
      .set('email', email)
    };
    return this.http.get<any[]>(environment.url.getStats, options).pipe(
      retry(3),
      map((res) => {
        const stats = {};
        res['studente_groupby_competenza_id_libro_id'].forEach((object) => {
          if (!Array.isArray(stats[object.sku])) {
            stats[object.sku] = [];
          }
          stats[object.sku].push(object);
        });
        this.stats = stats;
        return this.stats;
      }),
    );
  }

  getStatsGeneraliLibro(): Observable<void | any> {
    if (!!this.statsGeneraliLibro) {
      return new BehaviorSubject(this.statsGeneraliLibro).asObservable();
    }

    const username = this.authService.getUsername();
    const email = this.authService.getEmail();
    const options = {params: new HttpParams()
      .set('username', username)
      .set('email', email)
    };
    return this.http.get<any[]>(environment.url.getStats, options).pipe(
      retry(3),
      map((res) => {
        const stats = {};
        res['studente_groupby_libro_id'].forEach((object) => {
          stats[object.sku] = object;
        });
        this.statsGeneraliLibro = stats;
        return this.statsGeneraliLibro;
      }),
    );
  }

  getStatsGeneraliStudente(): Observable<void | any> {
    if (!!this.statsGeneraliStudente) {
      return new BehaviorSubject(this.statsGeneraliStudente).asObservable();
    }

    const username = this.authService.getUsername();
    const email = this.authService.getEmail();
    const options = { params: new HttpParams()
      .set('username', username)
      .set('email', email)
    };
    return this.http.get<any[]>(environment.url.getStats, options).pipe(
      retry(3),
      map((res) => {
        this.statsGeneraliStudente = res['studente_groupby_competenza_id_livello_id'];
        return this.statsGeneraliStudente;
      }),
    );
  }

  filterBooksByLanguage(books: Book[], lang: string) {
    return books.filter(book => {
      return !isNullOrUndefined(book.translations[lang]) ? book.translations[lang] : book.translations['en'] ;
    });
  }

  unlockCoupon(code: string) {
    const url = environment.url.unlockCoupon;

    const body = new HttpParams()
      .set('email', this.authService.getEmail())
      .set('username', this.authService.getUID())
      .set('code', code)
      .set('token', this.authService.getToken());

    return this.http.post(url, body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }).pipe(
      retry(3)
    );
  }

  private newBooks(items) {
    return items.map(({id, name, sku, coverImages, translations, webapp_url, releaseDate, metadatas}) => {
      const date = moment(releaseDate).format('DD-MM-YYYY');
      return new Book().deserialize({
        id,
        cover: coverImages,
        'published': (date === 'Invalid date' ? '' : date),
        sku,
        itemId: name,
        translations,
        metadatas
      });
    });
  }
}
