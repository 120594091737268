import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  options = {
    preventDuplicates: true,
    timeOut: 8000,
    showProgressBar: false,
    pauseOnHover: false,
    clickToClose: true,
    maxLength: 10
  };

  constructor(private _service: NotificationsService) {
  }

  success(title: string, message: string) {
    this._service.success(
      title,
      message,
      this.options
    );
  }

  httpError(message = 'Si è verificato un errore') {
    this._service.error(
      '',
      message,
      this.options
    );
  }
}
