import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  broadcastCoupon = new Subject();
  notifyObservableCoupon$ = this.broadcastCoupon.asObservable();

  broadcastReader = new Subject();
  notifyObservableReader$ = this.broadcastReader.asObservable();

  broadcastNewClass = new Subject();
  notifyObservableNewClass$ = this.broadcastNewClass.asObservable();

  broadcastEditClass = new Subject();
  notifyObservableEditClass$ = this.broadcastEditClass.asObservable();

  broadcastDeleteExercise = new Subject();
  notifyObservableDeleteExercise$ = this.broadcastDeleteExercise.asObservable();

  constructor() { }

  openModalCoupon() {
    this.broadcastCoupon.next(true);
  }

  closeModalCoupon() {
    this.broadcastCoupon.next(false);
  }

  openModalReader(url) {
    this.broadcastReader.next(url);
  }

  closeModalReader() {
    this.broadcastReader.next('');
  }

  openModalNewClass() {
    this.broadcastNewClass.next(true);
  }

  closeModalNewClass() {
    this.broadcastNewClass.next(false);
  }

  openModalEditClass(classroom) {
    this.broadcastEditClass.next(classroom);
  }

  closeModalEditClass() {
    this.broadcastEditClass.next(null);
  }

  openModalDeleteExercises(classroom) {
    this.broadcastDeleteExercise.next(classroom);
  }

  closeModalDeleteExercises() {
    this.broadcastDeleteExercise.next(null);
  }
}
