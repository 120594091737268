import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UnauthorizedResponseInterceptor } from './auth.interceptor';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { AuthGuardService } from './auth-guard.service';
import { NavigationComponent } from './navigation/navigation.component';
import { CapitalizeFirstPipe } from './pipes/capitalizefirst.pipe';
import { CapitalizeEachWordPipe } from './pipes/capitalizeeachword.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { CouponUnlockerComponent } from './coupon-unlocker/coupon-unlocker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BookComponent } from './book/book.component';
import { ClarityModule, ClrFormsNextModule } from '@clr/angular';
import { ModalCouponComponent } from './modal-coupon/modal-coupon.component';
import { LowercasePipe } from './pipes/lowercase.pipe';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ModalReaderComponent } from './modal-reader/modal-reader.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { MyDatePickerModule } from 'mydatepicker';
import { MyClassroomsStudentComponent } from './my-classrooms-student/my-classrooms-student.component';
import { ParticipateClassComponent } from './participate-class/participate-class.component';
import { ButtonAddClassroomComponent } from './button-add-classroom/button-add-classroom.component';
import { GeneralStatsComponent } from './general-stats/general-stats.component';
import { ClassroomStatsComponent } from './classroom-stats/classroom-stats.component';
import { MyStudentStatsComponent } from './my-student-stats/my-student-stats.component';
import { ModalEditClassComponent } from './modal-edit-class/modal-edit-class.component';
import { EditClassComponent } from './edit-class/edit-class.component';
import { TeacherBookStatsComponent } from './teacher-book-stats/teacher-book-stats.component';
import { MyStatsComponent } from './my-stats/my-stats.component';
import { StatsComponent } from './stats/stats.component';
import { MyClassroomsTeacherComponent } from './my-classrooms-teacher/my-classrooms-teacher.component';
import { NewClassComponent } from './new-class/new-class.component';
import { ModalNewClassComponent } from './modal-new-class/modal-new-class.component';
import { ButtonBookReadComponent } from './button-book-read/button-book-read.component';
import { LoginMobileComponent } from './login-mobile/login-mobile.component';

import {RoleGuardService} from './role-guard.service';
import { InfoComponent } from './info/info.component';
import { ModalDeleteExercisesComponent } from './modal-delete-exercises/modal-delete-exercises.component';
import { DeleteExercisesComponent } from './delete-exercises/delete-exercises.component';
import { CarouselComponent } from './carousel/carousel.component';

// import { GtagModule } from 'angular-gtag';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const appRoutes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuardService], data: { state: 'home' } },
  { path: 'book/:id', component: BookComponent, canActivate: [AuthGuardService], data: { state: 'bookItem' } },
  { path: 'my-stats', component: MyStatsComponent, canActivate: [AuthGuardService], data: { state: 'my-stats' } },
  { path: 'teacher/my-classrooms', component: MyClassroomsTeacherComponent, canActivate: [AuthGuardService, RoleGuardService],
    data: { state: 'my-classrooms-teacher' } },
  { path: 'teacher/my-classrooms/:classroom', component: ClassroomStatsComponent, canActivate: [AuthGuardService, RoleGuardService],
    data: { state: 'classroom-stats' } },
  { path: 'teacher/my-classrooms/:classroom/books/:sku', component: TeacherBookStatsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    data: { state: 'teacher-my-books' } },
  { path: 'student/my-classrooms', component: MyClassroomsStudentComponent, canActivate: [AuthGuardService],
    data: { state: 'my-classrooms-student' } },
  { path: 'teacher/my-classrooms/:classroom/students/:username', component: MyStudentStatsComponent, canActivate: [AuthGuardService,
      RoleGuardService],
    data: { state: 'my-students-stats' } },
  { path: 'login', component: LoginComponent, data: { state: 'login' }},
  { path: 'login-mobile', component: LoginMobileComponent, data: { state: 'login-mobile' } },
  { path: 'info', component: InfoComponent, data: { state: 'info'} },
  { path: 'who-we-are', component: WhoWeAreComponent, data: { state: 'who-we-are'} },
  { path: '404', component: PageNotFoundComponent, data: { state: '404' } },
  { path: '**', component: PageNotFoundComponent, data: { state: '404' } }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    HomeComponent,
    NavigationComponent,
    CapitalizeFirstPipe,
    CapitalizeEachWordPipe,
    LowercasePipe,
    CatalogueComponent,
    CouponUnlockerComponent,
    BookComponent,
    MyStatsComponent,
    ModalCouponComponent,
    ModalReaderComponent,
    StatsComponent,
    ModalCouponComponent,
    ModalReaderComponent,
    WhoWeAreComponent,
    MyClassroomsTeacherComponent,
    NewClassComponent,
    ModalNewClassComponent,
    MyClassroomsStudentComponent,
    ParticipateClassComponent,
    ButtonAddClassroomComponent,
    GeneralStatsComponent,
    ClassroomStatsComponent,
    MyStudentStatsComponent,
    ModalEditClassComponent,
    EditClassComponent,
    TeacherBookStatsComponent,
    ButtonBookReadComponent,
    LoginMobileComponent,
    InfoComponent,
    ModalDeleteExercisesComponent,
    DeleteExercisesComponent,
    CarouselComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes,
    ),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ClarityModule,
    ClrFormsNextModule,
    NgSelectModule,
    SimpleNotificationsModule.forRoot(),
    MyDatePickerModule,
    // GtagModule.forRoot({ trackingId: 'G-M9T2GVGSFR', trackPageviews: false, debug: true })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedResponseInterceptor,
      multi: true
    },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
