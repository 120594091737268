import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../notification.service';
import {ModalService} from '../modal.service';
import {BooksService} from '../books.service';

@Component({
  selector: 'app-coupon-unlocker',
  templateUrl: './coupon-unlocker.component.html',
  styleUrls: ['./coupon-unlocker.component.scss']
})
export class CouponUnlockerComponent implements OnInit {

  form: FormGroup;
  triedToSubmit = false;
  loading = false;

  constructor(private fb: FormBuilder, private _translate: TranslateService,
              private _notificationService: NotificationService,
              private _modalService: ModalService,
              private _booksService: BooksService) { }

  get code() { return this.form.get('code'); }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      code: [ '', Validators.required ]
    });
  }

  onSubmit() {
    this.triedToSubmit = false;

    if (this.form.valid) {
      this.loading = true;
      this._booksService.unlockCoupon(this.form.get('code').value).subscribe(res => {
        if (res['status'] !== 'KO' && 'books' in res && res['books'].length !== 0) {
          this._booksService.reload();
          this._notificationService.success('Coupon riscattato con successo', '');
          this._modalService.closeModalCoupon();
        } else {
          this.loading = false;
/*          this._translate.get('notifications.code-noclass').subscribe((resp: string) => {
            this._notificationService.httpError(resp);
          });*/
          this._notificationService.httpError('Il codice coupon non è valido');
        }
      }, err => {
        console.log(err);
        this.resetSubmit();
        this.loading = false;
      }, () => {
        this.resetSubmit();
        this.loading = false;
      });
    } else {
      this.triedToSubmit = true;
    }
  }

  resetSubmit() {
    this.form.reset();
  }
}
