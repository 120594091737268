import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

declare let gtag: Function;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  nav$ : Subscription;

  constructor(private _router: Router) {
    this.nav$ = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // gtag('event', 'page_view', {
        //   "page_title" : "Homepage",
        //   "page_location": window.location.href,
        //   "page_path": window.location.pathname
        // });
        gtag("event", "screen_view", {
          // "app_name" : "eReaders",
          "screen_name" : "catalog/home",
        });
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.nav$.unsubscribe();
  }

}
