import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-modal-new-class',
  templateUrl: './modal-new-class.component.html',
  styleUrls: ['./modal-new-class.component.scss']
})
export class ModalNewClassComponent implements OnInit, OnDestroy {

  state = false;

  constructor(private _modalService: ModalService) {
    this._modalService.notifyObservableNewClass$.subscribe((state: boolean) => {
      this.state = state;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._modalService.broadcastNewClass.unsubscribe();
  }
}
