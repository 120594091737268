import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-modal-coupon',
  templateUrl: './modal-coupon.component.html',
  styleUrls: ['./modal-coupon.component.scss']
})
export class ModalCouponComponent implements OnInit, OnDestroy {

  state = false;

  constructor(private modalService: ModalService) {
    this.modalService.notifyObservableCoupon$.subscribe((state: boolean) => { this.state = state; });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.modalService.broadcastCoupon.unsubscribe();
  }
}
