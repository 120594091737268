export class BookStats {
  average_closed: string;
  totalex_closed: string;
  totalsent_closed: string;
  totalex_open: string;
  totalsent_open: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  getTotalStat() {
    const total_closed = parseFloat(this.totalex_closed);
    const totalsent_closed = parseFloat(this.totalsent_closed);
    const totalex_open = parseFloat(this.totalex_open);
    const totalsent_open = parseFloat(this.totalsent_open);

    return (100 * (totalsent_closed + totalsent_open) / (total_closed + totalex_open)).toFixed(1);
  }
}
