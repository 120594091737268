import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from '../modal.service';

@Component({
  selector: 'app-modal-edit-class',
  templateUrl: './modal-edit-class.component.html',
  styleUrls: ['./modal-edit-class.component.scss']
})
export class ModalEditClassComponent implements OnInit, OnDestroy {

  state;

  constructor(private _modalService: ModalService) {
    this._modalService.notifyObservableEditClass$.subscribe((state) => {
      this.state = state;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._modalService.broadcastEditClass.unsubscribe();
  }
}
