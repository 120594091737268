import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private _authService: AuthService ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this._authService.isAuthenticated().subscribe((isAuthenticated) => {
        if (!isAuthenticated) this.router.navigate(['login']);
        observer.next(isAuthenticated);
        observer.complete();
      });
    });
  }

}