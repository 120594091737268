import { Injectable } from '@angular/core';
import {isNullOrUndefined} from 'util';
import * as moment from 'moment';
import { setCookie, getCookie, deleteAllCookies } from './utils';
import {environment} from '../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {retry} from 'rxjs/operators';
import { Router } from '@angular/router';
import { from, Observable, of as observableOf } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// import { Gtag } from 'angular-gtag';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private roles = {
    'ROLE_STUDENT': 'Student',
    'ROLE_TEACHER': 'Teacher'
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private _translate: TranslateService
  ) {}


  public async login(username, password) {
    // login to Dea backend to obtain credentials
    var deaRslt = await this.http.post<any>(environment.url.deaLogin, {
      "username" : username,
      "password" : password
    }).toPromise();
    // console.log(rslt);

    const accessToken = deaRslt.access_token;
    // const uid = deaRslt.user.id;

    return this.loginWithToken(accessToken);
  }

  private async loginWithToken(accessToken) {
    // console.log(accessToken);
    var httpOptions = { headers: new HttpHeaders({
      Authorization: `Bearer ${accessToken}`
    })};

    var deaRslt = await this.http.get<any>(environment.url.userProfile, httpOptions).toPromise();
    // console.log(deaRslt);

    // login to proxy / shelf cms / stats cms
    httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    };
    var body = new HttpParams()
      .set('accessToken', accessToken)
      .set('uid', deaRslt.id)
      .set('role', deaRslt.role)
      .set('email', deaRslt.emails[0])
      .set('firstname', deaRslt.firstname)
      .set('lastname', deaRslt.lastname);
    var proxyRslt = await this.http.post<object>(environment.url.proxyLogin, body, httpOptions).toPromise();

    // authenticate frontend
    this.authenticate(accessToken, deaRslt.id, deaRslt.role, deaRslt.emails[0], deaRslt.firstname, deaRslt.lastname);

    return true;
  }


  public isAuthenticated(): Observable<boolean> {
    var deaToken = getCookie("deatoken");
    if (deaToken != "") {
      console.log("trying to restore authentication with deatoken...");
      localStorage.clear();
      deleteAllCookies();
      document.cookie = `deatoken=;domain=.${environment.topLevelDomain};expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      return from(this.loginWithToken(deaToken));
    }

    const token = this.getToken();
    const username = this.getUsername();
    if (!(token === '' || isNullOrUndefined(username))) {
      gtag('set', {
        'user_id': this.getEmail(),
      });
      gtag('set', 'user_properties', {
        'language': this._translate.currentLang
      });
      return observableOf(true);
    } 

    return observableOf(false);
  }

  public authenticate(token: string, uid: string, role: string, email: string, nome: string, cognome: string) {
    localStorage.setItem('nome', nome);
    localStorage.setItem('cognome', cognome);
    localStorage.setItem('email', email);
    localStorage.setItem('uid', uid);
    localStorage.setItem('role', role);
    localStorage.setItem('email', decodeURI(email));
    const expireSeconds = 60*60*24*30*2; // 2 months
    setCookie('ereaders_token', token, expireSeconds);
    gtag('event','login', { 
      user: email,
    });
    gtag('set', {
      'user_id': email,
    });
    gtag('set', 'user_properties', {
      'language': this._translate.currentLang
    });
  }

  public renewToken(token: string, expireToken: string) {
    const expireSeconds = moment.unix(parseInt(expireToken, 10)).diff(moment(), 'seconds');
    setCookie('ereaders_token', token, expireSeconds);
  }

  public getEmail() {
    return localStorage.getItem('email');
  }

  public getRole() {
    return localStorage.getItem('role');
  }

  public getUID() {
    return localStorage.getItem('uid');
  }

  public logout() {
    localStorage.clear();
    deleteAllCookies();
    this.router.navigate(['login']);
  }

  public isStudent() {
    return this.roles[localStorage.getItem('role')] === 'Student';
  }

  public isTeacher() {
    return this.roles[localStorage.getItem('role')] === 'Teacher';
  }

  getNome() {
    return localStorage.getItem('nome');
  }

  getCognome() {
    return localStorage.getItem('cognome');
  }

  getToken() {
    return getCookie('ereaders_token');
  }

  getUsername() {
    return localStorage.getItem('uid');
  }
}
