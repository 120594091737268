import {Component, Input, OnInit} from '@angular/core';
import {Book} from '../models/book';
import {PopupService} from '../popup.service';

@Component({
  selector: 'app-button-book-read',
  templateUrl: './button-book-read.component.html',
  styleUrls: ['./button-book-read.component.scss']
})
export class ButtonBookReadComponent implements OnInit {

  @Input() book: Book;
  @Input() viewMode = 'standard';
  public viewModes = {
    'standard': 'btn-primary btn-round',
    'alternate': 'btn-card btn-border-bottom-red align-middle'
  };
  public isOpening = false;

  constructor(
    private _popupService: PopupService) {
    this._popupService.notifyObservableBookLoaded$.subscribe((book: Book) => {
      if (book.sku === this.book.sku) {
        this.isOpening = false;
      }
    });
  }

  ngOnInit() {
  }

  openNewWindowReader(book: Book) {
    this.isOpening = true;
    this._popupService.openNewWindowReader(book);
  }
}
