const host = 'https://cideb.conversa-dev.it/';

export const environment = {
  production: true,
  languages: [
    'en', 'it', 'fr', 'es', 'de'
  ],
  topLevelDomain: "cideb-web.conversa-dev.it",
  url: {
    deaLogin: "https://identity.temp.a.deascuola.it/v1/login",
    userProfile: "https://identity.temp.a.deascuola.it/v1/me",
    proxyLogin: host + 'login/',
    // iframeLogin: host + 'iframe/',
    getApplication: host + 'get-application/',
    getBooks: host + 'get-books/',
    reader: host + 'reader/',
    getStats: host + 'get-stats/',
    createClass: host + 'create-class/',
    editClass: host + 'update-class/',
    getClassesTeacher: host + 'get-classes-teacher/',
    getClassesStudent: host + 'get-classes-student/',
    participateClass: host + 'participate-class/',
    getClassroomStats: host + 'get-classroom-stats/',
    getExercisesByClass: host + 'get-exercises/',
    deleteExercises: host + 'delete-exercises/',
    logout: 'https%3A%2F%2Fcideb-web.conversa-dev.it%2Flogin',
    unlockCoupon: host + 'unlock-coupon/',
  }
};
