import { Component, OnInit } from '@angular/core';
import { ClassService } from '../class.service';
import { NotificationService } from '../notification.service';
import { fade } from '../animations';
import { Classroom } from '../models/classroom';
import { ModalService } from '../modal.service';
import { Student } from '../models/student';
import { NavigationEnd, Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { Subscription } from 'rxjs';

declare let gtag: Function;

@Component({
  selector: 'app-my-classrooms',
  templateUrl: './my-classrooms.component.html',
  styleUrls: ['./my-classrooms.component.scss'],
  animations: [ fade ]
})
export class MyClassroomsTeacherComponent implements OnInit {

  classes;
  exercisesByClass;
  loading = false;
  openedClass: Classroom;
  tooltipMessage = '';
  feedbackMessage = '';

  nav$ : Subscription;
  
  constructor(private _classService: ClassService,
              private _notificationService: NotificationService,
              private _modalService: ModalService,
              private _router: Router,
              private _translate: TranslateService
  ) {
    this.loadClasses();
    this._classService.reload$.subscribe(() => {
      this.loadClasses();
    });
    this.updateTooltipMessage();
    this._translate.onLangChange.subscribe(() => {
      this.updateTooltipMessage();
    });
    this.nav$ = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // gtag('event', 'page_view', {
        //   "page_title" : "Le mie classi",
        //   "page_location": window.location.href,
        //   "page_path": window.location.pathname
        // });
        gtag("event", "screen_view", {
          // "app_name" : "eReaders",
          "screen_name" : "cideb_teacher_myclassrooms",
        });
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.nav$.unsubscribe();
  }

  private updateTooltipMessage() {
    this._translate.get('class.click-tip').subscribe((resp) => {
      this.tooltipMessage = resp;
    });
    this._translate.get('class.click-feedback').subscribe((resp) => {
      this.feedbackMessage = resp;
    });
  }

  public openAccordionClass(classroom: Classroom) {
    this.openedClass === classroom ? this.openedClass = null : this.openedClass = classroom;
  }

  public copyCodeToClipboard(token: string) {
    const textArea = document.createElement('textarea');
    textArea.value = token;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    const stringbkp = this.tooltipMessage;
    this.tooltipMessage = this.feedbackMessage;
    document.body.removeChild(textArea);
    setTimeout(() => {
      this.tooltipMessage = stringbkp;
    }, 1000);
  }

  openEditClassModal(classroom: Classroom) {
    this._modalService.openModalEditClass(classroom);
  }

  openDeleteExercisesModal(classroom: Classroom) {
    this._modalService.openModalDeleteExercises(classroom);
  }

  goToStudentStat(classroom: Classroom, studente: Student) {
    this._router.navigate(['teacher/my-classrooms', classroom.token, 'students', studente.username]);
  }

  private loadClasses() {
    this.classes = [];
    this.loading = true;
    this._classService.getTeacherClasses().subscribe(
      (res) => {
        this.classes = res;

        this._classService.getExercisesByClass(this.classes).subscribe((res2) => {
          this.exercisesByClass = res2;

          this.loading = false;
        });
      },
      err => {
        this.loading = false;
        this._notificationService.httpError();
        console.error(err);
      });
  }
}
