import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ClassService} from '../class.service';
import {BooksService} from '../books.service';
import {TranslateService} from '@ngx-translate/core';
import {Book} from '../models/book';
import {combineLatest, Subscription} from 'rxjs';
import {Classroom} from '../models/classroom';

declare let gtag: Function;

@Component({
  selector: 'app-teacher-book-stats',
  templateUrl: './teacher-book-stats.component.html',
  styleUrls: ['./teacher-book-stats.component.scss']
})
export class TeacherBookStatsComponent implements OnInit, OnDestroy {

  classroom$: Subscription;
  classroom: Classroom;
  loading = true;
  statBook: any;
  bookCompetenzaStats: any[];
  currentLanguage = 'en';
  classroomToken: string;
  book: Book;
  getTotalStat = Book.getTotalStat;
  badgeClass = Book.badgeClass;
  getAverageStatByBook = Book.getAverageStatByBook;

  constructor(private _route: ActivatedRoute,
              private _classService: ClassService,
              private _bookService: BooksService,
              private _translate: TranslateService) {
    this.currentLanguage = this._translate.currentLang;
    this._translate.onLangChange.subscribe((event) => {
      this.currentLanguage = event.lang;
    });

    const sku = this._route.snapshot.paramMap.get('sku');
    this.classroomToken = this._route.snapshot.paramMap.get('classroom');

    this.classroom$ = this._classService.getTeacherClasses().subscribe((classes) => {
      const classroom1 = classes.find((classroom: Classroom) => {
        return classroom.token === this.classroomToken;
      });
      this.classroom = classroom1;
      return classroom1;
    });

    combineLatest(this._bookService.getCatalogue(), this._classService.getClassroomStats(this.classroomToken), (books, stats) => ({
      books, stats})).subscribe(({books, stats}) => {
      this.loading = true;

      this.book = books.find(book => {
        return sku === book.sku;
      });

      this.statBook = stats['docente_groupby_libro_id'].find(stat => {
        return stat.sku === sku;
      });

      this.bookCompetenzaStats = stats['docente_groupby_libro_id_competenza_id'].filter(stat => {
        return stat.sku === sku;
      });

      gtag("event", "screen_view", {
        "screen_name" : "cideb_teacher_classroom_bookstats/" + this.classroom.nome + " (" + this.classroom.token + ")/" + this.book.itemId,
      });

      this.loading = false;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.classroom$.unsubscribe();
  }

}
