import {Component, OnDestroy, OnInit} from '@angular/core';
import { Book } from '../models/book';
import { BooksService } from '../books.service';
import { ActivatedRoute } from '@angular/router';
import { PopupService } from '../popup.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { fade } from '../animations';
import {NotificationService} from '../notification.service';
import {BookStats} from '../models/book-stats';
import {AuthService} from '../auth.service';
import { Title } from '@angular/platform-browser';

declare let gtag: Function;

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
  animations: [ fade ]
})
export class BookComponent implements OnInit, OnDestroy {

  loadingBook = true;
  loadingStat = true;
  book$: Subscription;
  stat$: Subscription;
  book: Book;
  getTotalStat = Book.getTotalStat;
  badgeClass = Book.badgeClass;
  getAverageStatByBook = Book.getAverageStatByBook;

  constructor(private _booksService: BooksService,
              private _route: ActivatedRoute,
              private _popupService: PopupService,
              private _translate: TranslateService,
              private _notificationService: NotificationService,
              private _authService: AuthService,
              private titleService: Title) {
    const id = parseInt(this._route.snapshot.paramMap.get('id'), 10);
    this.book$ = this._booksService.getBooks().subscribe(
      (res: Book[]) => {
        this.book = res.find(book => book.id === id);
        this.loadingBook = false;
        // console.log(this.book);
        var pageTitle = `“${this.book.getTitleByLanguage(this.currentLanguage())}” | ${this.book.isAudio === true ? "Audiobooks" : "Ebooks"}`
        // titleService.setTitle(pageTitle);
        // gtag('event', 'page_view', {
        //   "page_title" : pageTitle,
        //   "page_location": window.location.href,
        //   "page_path": window.location.pathname
        // });
        gtag("event", "screen_view", {
          "screen_name" : `detail/${this.book.itemId}`,
        });

        this.stat$ = this._booksService.getStats().subscribe(
          (res2) => {
            if (!!res2[this.book.sku]) {
              this.book.stats = res2[this.book.sku].map((stat: object) => {
                return new BookStats().deserialize(stat);
              });
            }
            this.loadingStat = false;
          },
          err => {
            this.loadingStat = false;
            this._notificationService.httpError();
            console.error(err);
          });
      },
      err => {
        this.loadingBook = false;
        this._notificationService.httpError();
        console.error(err);
      });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.book$.unsubscribe();
    this.stat$.unsubscribe();
  }

  openNewWindowReader() {
    this._popupService.openNewWindowReader(this.book);
  }

  currentLanguage(): string {
    return this._translate.currentLang;
  }

  isTeacher(): boolean {
    return this._authService.isTeacher();
  }
}
