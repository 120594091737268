export const languagesLowercase = {
  'en': 'english',
  'it': 'italian',
  'es': 'spanish',
  'de': 'german',
  'fr': 'french'
};

export const languagesFirstUppercase = {
  'en': 'English',
  'it': 'Italiano',
  'es': 'Español',
  'de': 'Deutsch',
  'fr': 'Français',
};

export const languageMappingFromItalian = {
  'Inglese': 'en',
  'Italiano': 'it',
  'Spagnolo': 'es',
  'Tedesco': 'de',
  'Francese': 'fr',
};

export const languageMappingFromEnglish = {
  'English': 'en',
  'Italiano': 'it',
  'Español': 'es',
  'Deutsch': 'de',
  'Français': 'fr',
};
