import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BooksService} from '../books.service';
import {NotificationService} from '../notification.service';
import {ModalService} from '../modal.service';
import {ClassService} from '../class.service';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {Book} from '../models/book';
import {Classroom} from '../models/classroom';

declare let gtag: Function;

@Component({
  selector: 'app-delete-exercises',
  templateUrl: './delete-exercises.component.html',
  styleUrls: ['./delete-exercises.component.scss']
})
export class DeleteExercisesComponent implements OnInit, OnDestroy {

  class;
  form: FormGroup;
  loading = false;
  submitted = false;
  triedToSubmit = false;
  currentLanguage = 'en';
  catalogue = [];
  books = [];
  booksByLanguage = [];
  currentBook: Book;
  currentCapitolo;
  book_chapters = [];
  book_exercises = [];
  classroom_students;
  labels = {
    'books': '',
    'students': '',
    'exercises': '',
    'chapters': ''
  };
  selectExercisesDisabled = true;

  constructor(private fb: FormBuilder,
              private _booksService: BooksService,
              private _notificationService: NotificationService,
              private _modalService: ModalService,
              private _classService: ClassService,
              private _translate: TranslateService) {

    this.currentLanguage = this._translate.currentLang;
    this.generateLabels();
    this._translate.onLangChange.subscribe((event) => {
      this.currentLanguage = event.lang;
      this.generateLabels();
    });

    this._booksService.getCatalogue().subscribe((catalogue: Book[]) => {
      this.catalogue = catalogue;
    }, (err) => {
      console.log(err);
    });

    this._modalService.notifyObservableDeleteExercise$.pipe(filter(x => !!x)).subscribe((classroom: Classroom) => {
      this.class = classroom;

      this.books = this.class.libri;
      this.books.map((book: Book) => {
        return Object.assign(book, this.catalogue.find((book2: Book) => {
          return book.sku === book2.sku;
        }));
      });

      this.classroom_students = classroom.studenti.map((studente) => {
        return {
          nome: studente.nome + ' ' + studente.cognome,
          username: studente.username
        };
      });
      this.booksByLanguage = this.books.filter((book: Book) => {
        return this.class.iso === book.getLanguage(this.currentLanguage);
      });
      this.booksByLanguage.sort((a, b) => {
        if (a.title < b.title) { return -1; }
        if (a.title > b.title) { return 1; }
        return 0;
      });

    }, (err) => {
      console.log(err);
    });
  }

  get book() { return this.form.get('book'); }
  get chapter() { return this.form.get('chapter'); }
  get exercises() { return this.form.get('exercises'); }
  get students() { return this.form.get('students'); }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
  }

  createForm() {
    this.form = this.fb.group({
      book: [ null, Validators.required ],
      chapter: [ { value: null, disabled: true }, Validators.required ],
      exercises: [ { value: null, disabled: true }, Validators.required ],
      students: [ null, Validators.required ],
    });

    this.book.statusChanges.subscribe((value) => {
      if (value === 'VALID') {
        this.chapter.enable();
        this.currentBook = this.books.find((book: Book) => {
          return this.book.value === book.sku;
        });
        this.book_chapters = this.currentBook.capitolo;
      } else {
        this.chapter.setValue(null);
        this.exercises.setValue(null);
        this.chapter.disable();
        this.exercises.disable();
        this.book_chapters = [];
      }
    });
    this.chapter.statusChanges.subscribe((value) => {
      if (value === 'VALID') {
        this.exercises.enable();
        this.selectExercisesDisabled = false;
        this.currentCapitolo = this.book_chapters.find((capitolo) => {
          return this.chapter.value === capitolo.codice;
        });
        this.book_exercises = this.currentCapitolo.esercizi;
      } else {
        this.selectExercisesDisabled = true;
        this.exercises.setValue(null);
        this.exercises.disable();
        this.book_exercises = [];
      }
    });
  }

  onSubmitCreate() {
    if (this.form.valid) {
      this.triedToSubmit = false;
      this.submitted = true;
    } else {
      this.triedToSubmit = true;
    }
  }

  onSubmitConfirm() {
    if (this.form.valid) {
      this.loading = true;
      this._classService.deleteExercises(this.class, this.exercises.value, this.students.value).subscribe(res => {
        if (res.status !== 'KO') {
          this._modalService.closeModalDeleteExercises();
          this._classService.reload();
          this._translate.get('del-ex-modal.message.success').subscribe((resp) => {
            this._notificationService.success(resp, '');
          });
          gtag("event", "cideb_teacher_resetExcerizes", {
            "classroom" : this.class.nome + " (" + this.class.token + ")"
          });
        } else {
          this.loading = false;
        }
      }, err => {
        console.log(err);
        this._modalService.closeModalDeleteExercises();
        this.loading = false;
        this.resetSubmit();
      }, () => {
        this.loading = false;
        this.resetSubmit();
      });
    }
  }

  onCancelConfirm() {
    this.submitted = false;
  }

  resetSubmit() {
    this.form.reset();
    this.submitted = false;
  }

  selectAllExercises(e) {
    e.preventDefault();
    this.form.controls['exercises'].setValue(this.book_exercises.map(exercise => {
      return exercise.id;
    }));
  }

  selectAllStudents(e) {
    e.preventDefault();
    this.form.controls['students'].setValue(this.classroom_students.map((student) => student.username));
  }

  getBookFromSku() {
    return this.booksByLanguage.filter((book: Book) => {
      return this.form.get('book').value.indexOf(book.sku) !== -1;
    }).map((book: Book) => {
      return book.title;
    }).join(', ');
  }

  getExercisesSubmitted() {
    const submitted = [];
    this.exercises.value.forEach((exerciseCode) => {
      const exercise = this.currentCapitolo.esercizi.find((esercizio) => {
        return esercizio.id === exerciseCode;
      });
      submitted.push(exercise.label);
    });
    return submitted.join(', ');
  }

  getStudentsSubmitted() {
    const submitted = [];
    this.students.value.forEach((username) => {
      const studente = this.classroom_students.find((stud) => {
        return username === stud.username;
      });
      if (studente !== undefined) {
        submitted.push(studente.nome);
      }
    });
    return submitted.join(', ');
  }

  private generateLabels() {
    this._translate.get('class.select-book').subscribe((value) => this.labels.books = value);
    this._translate.get('class.select-chapters').subscribe((value) => this.labels.chapters = value);
    this._translate.get('class.select-book_exercises').subscribe((value) => this.labels.exercises = value);
    this._translate.get('class.select-students').subscribe((value) => this.labels.students = value);
  }
}
