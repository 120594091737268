import { Injectable } from '@angular/core';
import {environment} from '../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {retry} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {NotificationService} from './notification.service';
import {BehaviorSubject, Observable} from 'rxjs';
import { languageMappingFromEnglish } from './languages';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  application: object;
  private _languages: string[] = [];

  constructor(private _authService: AuthService,
              private _notificationService: NotificationService,
              private _http: HttpClient) {
  }

  public getApplication() {

    if (!!this.application) {
      return new BehaviorSubject(this.application).asObservable();
    }

    const url = environment.url.getApplication;

    return new Observable((observer) => {
      this._http.post<object>(url, {}, {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
      }).pipe(
        retry(3)
      ).subscribe((res) => {
        this.application = res;

        res['languages'].sort((lang1, lang2) => {
          if (lang1['position'] < lang2['position']) {
            return -1;
          } else if (lang1['position'] > lang2['position']) {
            return 1;
          }
          return 0;
        }).forEach((item) => {
          this._languages.push(languageMappingFromEnglish[item['language']['tostring']]);
        });
        environment['languages'] = this._languages;

        observer.next(this.application);
        observer.complete();
      }, (err) => {
        console.log(err);
        observer.error();
      });
    });
  }
}
