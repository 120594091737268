import { Injectable } from '@angular/core';
import { Book } from './models/book';
import { environment } from '../environments/environment';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from './modal.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NotificationService } from './notification.service';
import {isChrome, isSafari} from './utils';
import {Subject} from 'rxjs';
// import { Gtag } from 'angular-gtag';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private windowReader;
  broadcastBookLoaded = new Subject();
  notifyObservableBookLoaded$ = this.broadcastBookLoaded.asObservable();

  constructor(private _authService: AuthService,
              private _translate: TranslateService,
              private _modalService: ModalService,
              private _http: HttpClient,
              private _notificationService: NotificationService) { }

  openNewWindowReader(book: Book) {
    // console.log("opening...", book);
    if (isSafari() || isChrome()) {
      const windowParams = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=50,height=50';
      window.name = 'parent';
      const windowReader = window.open('', '_blank', windowParams);
      this.windowReader = windowReader;
      windowReader.blur();
      window.focus();
      windowReader.open('', 'parent').focus();
    }
    const uid = this._authService.getUID();
    const email = this._authService.getEmail();
    const token = this._authService.getToken();
    const role = this._authService.getRole();
    const body = new HttpParams()
      .set('username', uid)
      .set("email", email)
      .set('itemId', book.itemId)
      .set('sku', book.sku)
      .set('token', token)
      .set('role', role);
    // console.log(body);

    return this._http.post<any[]>(environment.url.reader, body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }).pipe(
    ).subscribe((res) => {
      // console.log(res);
      if (res['status'] === 'KO') {
        if (!isSafari() || isChrome()) {
          this._modalService.closeModalReader();
        }
        this.broadcastBookLoaded.next(book);
        this._notificationService.httpError('Non sei autorizzato a visualizzare la risorsa');
      } else {

        if (isSafari() || isChrome()) {
          this._modalService.closeModalReader();
          this.windowReader.location.href = res['url'];
          this.windowReader.resizeTo(screen.width, screen.height);
          this.windowReader.focus();
        } else {
          this._modalService.openModalReader(res['url']);
        }
        this.broadcastBookLoaded.next(book);

        gtag("event", "screen_view", {
          "screen_name" : `reader/${book.itemId}`,
        });
        gtag('event', 'launch_viewer', { 
          viewerId: "HtmlViewer",
          itemId: book.itemId,
          sku: book.sku
        });
      }
    });
  }
}
