import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ClassService } from '../class.service';
import { Classroom } from '../models/classroom';
import { NotificationService } from '../notification.service';
import {isNullOrUndefined} from 'util';
import {combineLatest} from 'rxjs';
import {Book} from '../models/book';
import {BooksService} from '../books.service';
import {TranslateService} from '@ngx-translate/core';

declare let gtag: Function;

@Component({
  selector: 'app-classroom-stats',
  templateUrl: './classroom-stats.component.html',
  styleUrls: ['./classroom-stats.component.scss']
})
export class ClassroomStatsComponent implements OnInit {

  loading = true;
  classroom: Classroom;
  classroomStatByLibro: any;
/*
  classroomStatByCompetenza: any;
*/
  classroomStatByLivello: any;
  currentLanguage = 'en';
  getTotalStat = Book.getTotalStat;
  badgeClass = Book.badgeClass;
  getAverageStatByBook = Book.getAverageStatByBook;
  objectKeys = Object.keys;

  constructor ( private _route: ActivatedRoute,
                private _classService: ClassService,
                private _notificationService: NotificationService,
                private _bookService: BooksService,
                private _translate: TranslateService,
                private _router: Router) {
    this.currentLanguage = this._translate.currentLang;
    this._translate.onLangChange.subscribe((event) => {
      this.currentLanguage = event.lang;
    });
    const token = this._route.snapshot.paramMap.get('classroom');

    this._classService.getTeacherClasses().subscribe(
      (res: Classroom[]) => {
        this.loading = true;
        const classroom = res.find(item => item.token === token);
        this.classroom = classroom;

        gtag("event", "screen_view", {
          "screen_name" : "cideb_teacher_classroom_stats/" + classroom.nome + " (" + classroom.token + ")",
        });

        combineLatest(this._classService.getClassroomStats(this.classroom.token),
          this._bookService.getCatalogue(), (stat, books) => ({
            stat, books
          })).subscribe(({stat, books}) => {
          this.loading = true;
          let stats = stat['docente_groupby_libro_id'];
          stats = stats.map((item) => {
            item['book'] = books.find((book: Book) => {
              return item.sku === book.sku;
            });
            return item;
          }).filter(item => {
            return !isNullOrUndefined(item['book']);
          });
          this.classroomStatByLibro = stats;
/*          this.classroomStatByCompetenza = stat['docente_groupby_competenza_id'].filter((statistic) => {
            return statistic['token_classe'] === token;
          });*/

          this.classroomStatByLivello = stats;
          this.classroomStatByLivello = stat['docente_groupby_competenza_id_livello_id'].filter((statistic) => {
            return statistic['token_classe'] === token;
          });
          const statsTemp = {};

          this.classroomStatByLivello.forEach(competence => {
            if (!Array.isArray(statsTemp[competence.nome_livello])) {
              statsTemp[competence.nome_livello] = [];
            }
            statsTemp[competence.nome_livello].push(competence);
          });

          this.classroomStatByLivello = statsTemp;
          this.loading = false;
        }, () => {
        }, () => {
        });
      },
      err => {
        this._notificationService.httpError();
        console.error(err);
      }, () => {
      });
  }

  ngOnInit() {
  }

  filteredBooksByLanguage() {
    return this.classroomStatByLibro.filter(item => {
      return !isNullOrUndefined(item.book.translations[this.currentLanguage]) ?
        item.book.translations[this.currentLanguage] : item.book.getFirstAvailableLanguageInMetadatasTranslations();
    });
  }

  getLanguage(language) {
    return Book.languages[Book.languageMappingFromItalian[language]].toLowerCase();
  }

  goToBookStat(classroom: string, book: string) {
    this._router.navigate(['teacher/my-classrooms', classroom, 'books', book]);
  }
}
