import {isDevMode} from '@angular/core';
import { environment } from 'src/environments/environment';

export function randomstring (L) {
  let s = '';
  const randomchar = function() {
    const n = Math.floor(Math.random() * 62);
    if (n < 10) { return n; }
    if (n < 36) { return String.fromCharCode(n + 55); }
    return String.fromCharCode(n + 61);
  };
  while (s.length < L) { s += randomchar(); }
  return s;
}

export function setCookie(name: string, value: string, expireSeconds: number, path: string = '') {
  const d: Date = new Date();
  d.setSeconds(d.getSeconds() + expireSeconds);
  const expires = `expires=${d.toUTCString()}`;
  const cpath: string = path ? `; path=${path}` : '';
  // const secureOnly = isDevMode() ? '' : '; secure';
  if (isDevMode()) {
    document.cookie = `${name}=${value}; ${expires}${cpath}; SameSite=Strict;`;
  } else {
    document.cookie = `${name}=${value}; ${expires}${cpath}; domain=.${environment.topLevelDomain};path=/; SameSite=Strict; secure`;
  }
}

export function getCookie(name: string) {
  const ca: Array<string> = document.cookie.split(';');
  const caLen: number = ca.length;
  const cookieName = `${name}=`;
  let c: string;

  for (let i = 0; i < caLen; i += 1) {
    c = ca[i].replace(/^\s+/g, '');
    if (c.indexOf(cookieName) === 0) {
      return c.substring(cookieName.length, c.length);
    }
  }
  return '';
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    // document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie = `${name}=;domain=.${environment.topLevelDomain};expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }
}

export function isSafari() {
  // @ts-ignore
  return /constructor/i.test(window.HTMLElement) || (function (p) {
    // @ts-ignore
    return p.toString() === '[object SafariRemoteNotification]';
    // @ts-ignore
  })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
  // return true;
}

export function getBrowserName() {
  const agent = window.navigator.userAgent.toLowerCase()
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'edge';
    case agent.indexOf('opr') > -1 && !!(<any>window).opr:
      return 'opera';
    case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'ie';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
}

export function isChrome() {
  if (getBrowserName() === 'chrome') {
    return true;
  } else {
    return false;
  }
}
