import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trigger, animate, style, query, transition } from '@angular/animations';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import { getCookie } from './utils';
// import { Gtag } from 'angular-gtag';

declare let gtag: Function;

export const routerTransition = trigger('animRoutes', [
  transition( '* => *', [
    query(':enter',
      [
        style({ opacity: 0 })
      ],
      { optional: true }
    ),
    query(':leave',
      [
        style({ opacity: 1, position: 'absolute' }),
        animate('0s', style({ opacity: 0 }))
      ],
      { optional: true }
    ),
    query(':enter',
      [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 }))
      ],
      { optional: true }
    )
  ])
]);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ routerTransition ],
})
export class AppComponent implements OnInit {

  public isLoginPage: boolean;
  public currentYear = (new Date()).getFullYear();
  currentUrl: string;

  constructor(private translate: TranslateService,
              private _router: Router) {
    translate.setDefaultLang('en');
    const languageToUse = !!getCookie('ereaders_lang') ? getCookie('ereaders_lang') : 'en';
    translate.use(languageToUse);
    gtag('set', 'user_properties', {
      'language': languageToUse
    });
  }

  ngOnInit() {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo( 0, 0 );
      }
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });

    console.warn("ngOnInit");

    var fn = function() {
      var doc = window.document;
      var footerContainer = doc.querySelector("footer");
      if (footerContainer) {
        var myScript = doc.createElement('script');
        myScript.setAttribute('type', 'text/javascript');
        myScript.setAttribute('src', "https://shared.deascuola.it/footer/index.footer.js");
        footerContainer.appendChild(myScript);
      } else {
        setTimeout(fn, 1000);
      }
    };
    fn();
    

    // <script type="text/javascript" src="https://shared.deascuola.it/footer/index.footer.js"></script>
  }

  public isMobile(): boolean {
    return !!(navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i));
  }

  public appUrl() {
    return this.isAndroid() ? 'https://play.google.com/store/apps/details?id=com.dealibri.deascuola&hl=it'
      : 'https://itunes.apple.com/it/app/ereaders-cideb-e-black-cat/id1011726742?mt=8';
  }

  public isLoginMobilePage(): boolean {
    return this._router.url === '/login-mobile';
  }

  public getPage(outlet): string {
    // necessario alle transition per determinare un cambio di stato e rendere valida regola * => *
    return outlet.activatedRouteData.state;
  }

  private isAndroid(): boolean {
    return !!navigator.userAgent.match(/Android/i);
  }
}
