import { Component, OnInit } from '@angular/core';
import { ClassService } from '../class.service';
import { NotificationService } from '../notification.service';
import { Classroom } from '../models/classroom';
import { fade } from '../animations';

@Component({
  selector: 'app-my-classrooms-student',
  templateUrl: './my-classrooms-student.component.html',
  styleUrls: ['./my-classrooms-student.component.scss'],
  animations: [ fade ]
})
export class MyClassroomsStudentComponent implements OnInit {

  classes;
  loading = false;

  constructor(private _classService: ClassService,
              private _notificationService: NotificationService) {
  }

  ngOnInit() {
    this.loadClasses();
    this._classService.reload$.subscribe(
      () => this.loadClasses()
    );
  }

  private loadClasses() {
    this.classes = [];
    this.loading = true;
    this._classService.getStudentClasses().subscribe(
      res => {
        this.loading = false;
        this.classes = res;
      },
      err => {
        this.loading = false;
        this._notificationService.httpError();
        console.error(err);
      });
  }
}
