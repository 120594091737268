import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  imagesBasePath = '/assets/images/catalogue/carousel/';
  images = [
    'BC_CB_banner_blog_Misterio-en-buenos-aires.jpg',
    'BC_CB_banner_blog_Mundo-del-fin-del-mundo.jpg',
    'BC_CB_banner_blog_wonder.jpg',
    'BC_CB_banner_EL-SUENO-DE-GOYA_.jpg',
    'BC_CB_banner_JEDER-STIRBT-FUR-SICH-ALLEIN_6.jpg',
    'BC_CB_banner_MARIE-CURIE_.jpg',
    'BC_CB_banner_the-LIGHTHOUSE-GHOST_.jpg',
    'BC_CB_banner_the-scarecrow-and-the-sunflower_3.jpg',
    'BC_CB_banner_the-strange-case-of-doctor-jekyll-and-mr-hyde_3.jpg',
    'BC_CB_banner_UN-SAC-DE-BILLES_.jpg'
  ];
  activeImage = this.images[0];
  index = 0;
  timeLeft = 5;
  interval;

  constructor() { }

  ngOnInit() {
    this.startTimer();
  }

  public nextSlide() {
    let index = this.findImage();
    if (index >= this.images.length - 1) {
      index = -1;
    }
    this.index = ++index;
    this.setImage();
  }

  public prevSlide() {
    let index = this.findImage();
    if (index === 0) {
      index = this.images.length;
    }
    this.index = --index;
    this.setImage();
  }

  private findImage() {
    return this.images.findIndex((el) => {
      return this.activeImage === el;
    });
  }

  private setImage() {
    this.activeImage = this.images[this.index];
    this.resetTimer();
  }

  private startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.resetTimer();
        this.nextSlide();
      }
    }, 1000);
  }

  private resetTimer() {
    this.timeLeft = 5;
  }
}
