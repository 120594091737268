import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ClassService} from '../class.service';
import {NotificationService} from '../notification.service';
import {ModalService} from '../modal.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-participate-class',
  templateUrl: './participate-class.component.html',
  styleUrls: ['./participate-class.component.scss']
})
export class ParticipateClassComponent implements OnInit {

  form: FormGroup;
  loading = false;
  triedToSubmit = false;

  constructor(private fb: FormBuilder, private _translate: TranslateService,
              private _notificationService: NotificationService,
              private _modalService: ModalService,
              private _classService: ClassService,
              private _router: Router) { }

  get code() { return this.form.get('code'); }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      code: [ '', Validators.required ]
    });
  }

  onSubmit() {
    this.triedToSubmit = false;
    if (this.form.valid) {
      this.loading = true;
      this._classService.participateClass(this.form.get('code').value).subscribe(res => {
        if (res.status !== 'KO') {
          this._router.navigateByUrl('/student/my-classrooms');
          this._classService.reload();
          this._notificationService.success('Classe aggiunta con successo', '');
        } else {
          this.loading = false;
          this._translate.get('notifications.code-noclass').subscribe((resp: string) => {
            this._notificationService.httpError(resp);
          });
        }
      }, err => {
        console.log(err);
        this.resetSubmit();
        this.loading = false;
      }, () => {
        this.resetSubmit();
        this.loading = false;
      });
    } else {
      this.triedToSubmit = true;
    }
  }

  resetSubmit() {
    this.form.reset();
  }
}
