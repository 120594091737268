import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../environments/environment';
import { map, retry } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Classroom } from './models/classroom';
import * as moment from 'moment';
import {Student} from './models/student';
import {Book} from './models/book';
import {isNullOrUndefined} from 'util';

@Injectable({
  providedIn: 'root'
})
export class ClassService {

  classes: Classroom[];
  stats: any;
  statsByClasses = [];
  statsByBook = [];
  exercisesByClass = [];
  reload$: EventEmitter<boolean>;

  constructor(private _http: HttpClient, private _authService: AuthService) {
    this.reload$ = new EventEmitter();
  }

  reload() {
    this.classes = null;
    this.reload$.emit(true);
  }

  createClass(nome: string, datafine: string = '', language: string): Observable<any> {
    const url = environment.url.createClass;

    const body = new HttpParams()
      .set('docente_email', this._authService.getEmail())
      .set('docente_username', this._authService.getUID())
      .set('nome', nome)
      .set('datafine', datafine)
      .set('iso', language);

    return this._http.post(url, body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }).pipe(
      retry(3)
    );
  }

  editClass(classroom: Classroom, books: string[]): Observable<any> {
    const url = environment.url.editClass;

    const body = new HttpParams()
      .set('token', classroom.token)
      .set('sku', JSON.stringify(books));

    return this._http.post(url, body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }).pipe(
      retry(3)
    );
  }

  deleteExercises(classroom: Classroom, exercises: string[], students: string[]): Observable<any> {
    const url = environment.url.deleteExercises;

    const body = new HttpParams()
      .set('classe', classroom.token)
      .set('esercizi', JSON.stringify(exercises))
      .set('studenti', JSON.stringify(students))
      .set('jkl', this._authService.getUsername());

    return this._http.post(url, body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }).pipe(
      retry(3)
    );
  }

  participateClass(token: string): Observable<any> {
    const url = environment.url.participateClass;

    const body = new HttpParams()
      .set('studente_email', this._authService.getEmail())
      .set('studente_username', this._authService.getUID())
      .set('token', token);

    return this._http.post(url, body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }).pipe(
      retry(3)
    );
  }

  getTeacherClasses(): Observable<Classroom[]> {
    if (!isNullOrUndefined(this.classes)) {
      return new BehaviorSubject(this.classes).asObservable();
    }

    const url = environment.url.getClassesTeacher;

    const body = new HttpParams().set('docente_email', this._authService.getEmail()).set('docente_username', this._authService.getUID());

    return this._http.post<Classroom[]>(url, body.toString(), {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    }).pipe(
      map((classes) => {
        this.classes = classes.filter((classroom) => {
          const date = moment(classroom.datafine);
          if (!date.isBefore(moment(), 'day')) {
            return classroom;
          }
        }).map((classroom) => {
          if (!!classroom.datafine) {
            classroom.datafine = moment(classroom.datafine).format('DD.MM.YYYY');
          }
          classroom.studenti = classroom.studenti.map(student => {
            return new Student().deserialize(student);
          });
          classroom.libri = classroom.libri.map(book => {
            return new Book().deserialize(book);
          });
          return new Classroom().deserialize(classroom);
        });
        return this.classes;
      }),
      retry(3)
    );
  }

  getStudentClasses() {
    if (!!this.classes) {
      return new BehaviorSubject(this.classes).asObservable();
    }

    const url = environment.url.getClassesStudent;

    const body = new HttpParams().set('studente_email', this._authService.getEmail()).set('studente_username', this._authService.getUID());

    return this._http.post<Classroom[]>(url, body.toString(), {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    }).pipe(
      map((classes) => {
        this.classes = classes.map((classroom) => {
          if (!!classroom.datafine) {
            classroom.datafine = moment(classroom.datafine).format('DD.MM.YYYY');
          }
          return new Classroom().deserialize(classroom);
        });
        return this.classes;
      }),
      retry(3)
    );
  }

  getClassroomStats(classroomToken = '') {

    if (classroomToken !== '' && !isNullOrUndefined(this.statsByClasses[classroomToken])) {
      return new BehaviorSubject(this.statsByClasses[classroomToken]).asObservable();
    } else if (classroomToken === '' && !isNullOrUndefined(this.stats)) {
      return new BehaviorSubject(this.stats).asObservable();
    }

    const url = environment.url.getClassroomStats;

    const body = new HttpParams().set('docente_username', this._authService.getUID()).set('token', classroomToken);

    return this._http.post<any[]>(url, body.toString(), {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    }).pipe(
      map((stats) => {
        this.stats = stats;
        this.statsByClasses[classroomToken] = stats;
        return stats;
      }),
      retry(3)
    );
  }

  getExercisesByClass(classes = []) {
    const url = environment.url.getExercisesByClass;

    const classes_token = classes.map((classroom: Classroom) => {
      return classroom.token;
    });

    const body = new HttpParams().set('classi', JSON.stringify(classes_token));

    return this._http.post<any[]>(url, body.toString(), {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    }).pipe(
      map((exercises) => {
        this.exercisesByClass = exercises;
        return this.exercisesByClass;
      }),
      retry(3)
    );
  }
}
