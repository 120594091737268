import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from '../modal.service';

@Component({
  selector: 'app-modal-delete-exercises',
  templateUrl: './modal-delete-exercises.component.html',
  styleUrls: ['./modal-delete-exercises.component.scss']
})
export class ModalDeleteExercisesComponent implements OnInit, OnDestroy {

  state;

  constructor(private _modalService: ModalService) {
    this._modalService.notifyObservableDeleteExercise$.subscribe((state) => {
      this.state = state;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._modalService.broadcastDeleteExercise.unsubscribe();
  }

}
