import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest, HttpErrorResponse, HttpParams, HttpResponse
} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class UnauthorizedResponseInterceptor implements HttpInterceptor {

  constructor(private _authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let request = req;

    if (req.url == environment.url.deaLogin || req.url == environment.url.proxyLogin) {
      return next.handle(req);
    }

    if (req.params.get('token')) {
      const username = this._authService.getUsername();
      const token = this._authService.getToken();
      const options = {params: new HttpParams().set('username', username).set('token', token)};

      request = req.clone(options);
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 500) { return EMPTY; }

        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return EMPTY;
          }

          if ('data' in err.error) {
            const token = err.error['data']['access_token'];
            const expires = err.error['data']['expires'];

            this._authService.renewToken(token, expires);

            const username = this._authService.getUsername();
            const options = {params: new HttpParams().set('username', username).set('token', token)};
            request = req.clone(options);

            return next.handle(request);
          } else {
            this._authService.logout();
            return EMPTY;
          }
        }
        return Observable.throw(err);
      }));
  }
}
