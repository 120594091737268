export class Student {
  id: number;
  email: string;
  username: string;
  nome: string;
  cognome: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  getNomeCognome() {
    return (this.nome || '') + ' ' + (this.cognome || '');
  }
}
