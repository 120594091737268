import {Student} from './student';
import {Book} from './book';

const languageMapping = {
  'en': 'english',
  'it': 'italian',
  'es': 'spanish',
  'de': 'german',
  'fr': 'french'
};

class Docente {
  nome: string;
  cognome: string;
}

export class Classroom {
  datafine: string;
  datainizio: string;
  id: number;
  nome: string;
  token: string;
  iso: string;
  studenti: Student[];
  libri: Book[];
  stats: any;
  docente: Docente;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  getLanguage() {
    return languageMapping[this.iso];
  }

  hasLibri() {
    return this.libri.length > 0;
  }

  getCommaSeparatedBooksByTitle() {
    return this.libri.map((book: Book) => {
      return book.title; 
    }).join(', ');
  }
}
