import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { Book } from '../models/book';
import { BooksService } from '../books.service';
import { Subscription } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { fade } from '../animations';
import {NotificationService} from '../notification.service';
import { FormGroup, FormControl } from '@angular/forms';

declare let gtag: Function;

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss'],
  animations: [ fade ]
})
export class CatalogueComponent implements OnInit, OnDestroy {

  loading = true;
  books$: Subscription;
  books: Book[];
  filteredBooks: Book[];

  filterForm: FormGroup;
  uniqueMetaLivello: Array<Object> = new Array<Object>();
  uniqueMetaLingua: Array<Object> = new Array<Object>();

  constructor(private _modalService: ModalService,
              private _booksService: BooksService,
              private _translate: TranslateService,
              private _notificationService: NotificationService) {

    this.filterForm = new FormGroup({
      lingua: new FormControl(),
      livello: new FormControl()
    });
    
    this.loadBooks();
    _booksService.reload$.subscribe(() => this.loadBooks());
  }

  ngOnInit() {
    this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // console.log(`language changed! ${this._translate.currentLang}`)
      this.updateFilters();
    });
  }

  ngOnDestroy() {
    this.books$.unsubscribe();
  }

  openModal() {
    this._modalService.openModalCoupon();
  }

  currentLanguage(): string {
    return this._translate.currentLang;
  }

  filteredBooksByLanguage() {
    return this._booksService.filterBooksByLanguage(this.books, this.currentLanguage());
  }

  sortBooksByLanguage(books) {
    return books.sort((a: Book, b: Book) => {
      if (a.title < b.title) { return -1; }
      if (a.title > b.title) { return 1; }
      return 0;
    });
  }

  private loadBooks() {
    this.books = [];
    this.loading = true;
    this.books$ = this._booksService.getBooks().subscribe(
      (res: Book[]) => {
        this.loading = false;
        this.books = this.sortBooksByLanguage(res);
        this.updateFilters();
        this.filterBooks();
      },
      () => {
        this.loading = false;
        this._notificationService.httpError();
      });
  }

  private updateFilters() {
    var uniqueMetaLivello = new Array<Object>();
    var uniqueMetaLingua = new Array<Object>();

    this.books.forEach(book => {
      var lingua = book.getMetadataDefaultValue("meta-lingua");
      if (lingua != null && !uniqueMetaLingua.includes(lingua)) {
        uniqueMetaLingua.push(lingua);
      }
      var livello = book.getMetadataDefaultValue("meta-livello");
      if (livello != null && !uniqueMetaLivello.includes(livello)) {
        uniqueMetaLivello.push(livello);
      }
    });

    uniqueMetaLingua.sort();
    uniqueMetaLingua = uniqueMetaLingua.map((val) => {
      var languageLabel = <string>val;
      var languageCode = Book.languagesFromNativeLanguageToISO[<string>val];
      if (languageCode !== undefined) languageLabel = this._translate.instant("languages."+languageCode);
      return { value:val, label:languageLabel }}
      );
    uniqueMetaLingua.unshift({ value:null, label: this._translate.instant("class.all-languages") });

    uniqueMetaLivello.sort();
    uniqueMetaLivello = uniqueMetaLivello.map((val) => { return { value:val, label:val }});
    uniqueMetaLivello.unshift({ value:null, label: this._translate.instant("stats.all-levels") });

    this.uniqueMetaLingua = uniqueMetaLingua;
    this.uniqueMetaLivello = uniqueMetaLivello;
    this.filterForm.get("lingua").setValue(null);
    this.filterForm.get("livello").setValue(null);
  }

  filterBooks() {
    var filterLingua = this.filterForm.get("lingua").value;
    var filterLivello = this.filterForm.get("livello").value;
    if (filterLingua != null || filterLivello != null) {
      var trackSearchEventParams = {};
      if (filterLingua != null) trackSearchEventParams["filter_meta_lingua"] = filterLingua;
      if (filterLivello != null) trackSearchEventParams["filter_meta_livello"] = filterLivello;
      gtag('event', 'search', trackSearchEventParams);
    }
    this.filteredBooks = this.books.filter(book => {
      if (filterLingua != null && book.getMetadataDefaultValue("meta-lingua") != filterLingua) return false;
      if (filterLivello != null && book.getMetadataDefaultValue("meta-livello") != filterLivello) return false;
      return true;
    });

    // console.log(`lingua: ${filterLingua}`);
    // console.log(`livello: ${filterLivello}`);
  }

  


}
